import { useState, useEffect, useRef } from 'react';
import { Article } from '../services/newsApi';
import { NewsCard } from './NewsCard';

interface HeroCarouselProps {
  articles: Article[];
}

const SLIDE_DURATION = 10000; // 10 seconds

export const HeroCarousel = ({ articles }: HeroCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef<number>();

  useEffect(() => {
    const startTime = Date.now();
    
    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      const newProgress = (elapsed / SLIDE_DURATION) * 100;
      
      if (newProgress >= 100) {
        setProgress(0);
        nextSlide();
      } else {
        setProgress(newProgress);
        progressInterval.current = requestAnimationFrame(updateProgress);
      }
    };

    progressInterval.current = requestAnimationFrame(updateProgress);

    return () => {
      if (progressInterval.current) {
        cancelAnimationFrame(progressInterval.current);
      }
    };
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((current) => (current + 1) % articles.length);
  };

  const prevSlide = () => {
    setCurrentIndex((current) => (current - 1 + articles.length) % articles.length);
  };

  if (!articles.length) return null;

  return (
    <div className="space-y-4">
      <div className="relative group">
        <div className="overflow-hidden relative">
          <div 
            className="flex transition-transform duration-500 ease-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {articles.map((article) => (
              <div key={article.id} className="w-full flex-shrink-0">
                <NewsCard article={article} featured />
              </div>
            ))}
          </div>

          {/* Progress Bar */}
          <div className="absolute bottom-0 left-0 w-full h-0.5 bg-gray-800">
            <div 
              className="h-full bg-blue-500/30 transition-transform duration-100 ease-linear origin-left"
              style={{ transform: `scaleX(${progress / 100})` }}
            />
          </div>
        </div>

        {/* Navigation Buttons */}
        <button
          onClick={prevSlide}
          className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
          aria-label="Previous slide"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/50 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
          aria-label="Next slide"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      {/* Thumbnail Navigation */}
      <div className="grid grid-cols-3 gap-4">
        {articles.map((article, index) => (
          <button
            key={article.id}
            onClick={() => setCurrentIndex(index)}
            className={`relative overflow-hidden rounded-lg transition-all duration-300 ${
              index === currentIndex 
                ? 'ring-2 ring-blue-500 scale-[1.02]' 
                : 'opacity-70 hover:opacity-100'
            }`}
          >
            <div className="aspect-[16/9]">
              <img
                src={article.imageUrl}
                alt={article.title}
                className="w-full h-full object-cover"
              />
              <div className={`absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent transition-opacity duration-300 ${
                index === currentIndex ? 'opacity-60' : 'opacity-40'
              }`} />
              <div className="absolute bottom-2 left-2 right-2">
                <h3 className="text-sm text-white font-medium line-clamp-2">
                  {article.title}
                </h3>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}; 