import axios from 'axios';

export interface Article {
  id: string;
  title: string;
  description: string;
  content: string;
  publishedAt: string;
  url: string;
  source: string;
  imageUrl: string;
  category: string;
  seoUrl: string;
}

export interface NewsResponse {
  articles: Article[];
  timestamp: string;
  isFromCache?: boolean;
}

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'http://localhost:3000',
});

const CACHE_KEY_PREFIX = 'news_cache_';
const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours
const ARTICLE_CACHE_DURATION = 30 * 60 * 1000; // 30 minutes

interface LocalCacheEntry<T> {
  data: T;
  timestamp: number;
}

// Generic cache helper functions
function getLocalCache<T>(key: string, duration: number): T | null {
  try {
    const cached = localStorage.getItem(key);
    if (!cached) return null;
    
    const { data, timestamp } = JSON.parse(cached) as LocalCacheEntry<T>;
    
    // Check if cache is expired
    if (Date.now() - timestamp > duration) {
      localStorage.removeItem(key);
      return null;
    }
    
    return data;
  } catch {
    return null;
  }
}

function setLocalCache<T>(key: string, data: T): void {
  try {
    localStorage.setItem(key, JSON.stringify({
      data,
      timestamp: Date.now()
    }));
  } catch {
    // Ignore storage errors
  }
}

export async function fetchNews(category?: string): Promise<NewsResponse> {
  const cacheKey = `${CACHE_KEY_PREFIX}${category || 'all'}`;
  const cached = getLocalCache<NewsResponse>(cacheKey, CACHE_DURATION);
  
  // If we have valid cache, return it immediately
  if (cached) {
    console.log(`[News] Returning cached ${category || 'all'} news`);
    return {
      ...cached,
      isFromCache: true
    };
  }

  try {
    // Fetch fresh data
    const endpoint = category ? `/news/${category}` : '/news';
    console.log(`[News] Fetching fresh ${category || 'all'} news`);
    const { data } = await api.get<NewsResponse>(endpoint);
    
    // Ensure data has the correct structure
    if (!Array.isArray(data.articles)) {
      console.error('[News] Invalid response format:', data);
      throw new Error('Invalid response format');
    }
    
    // Save to local cache
    setLocalCache<NewsResponse>(cacheKey, data);
    console.log(`[News] Cached ${data.articles.length} articles for ${category || 'all'}`);
    
    return {
      ...data,
      isFromCache: false
    };
  } catch (error) {
    console.error(`[News] Error fetching ${category || 'all'} news:`, error);
    return {
      articles: [],
      timestamp: new Date().toISOString(),
      isFromCache: false
    };
  }
}

export async function fetchArticle(seoUrl: string): Promise<Article | null> {
  try {
    // Extract the full article ID from the SEO URL
    const idMatch = seoUrl.match(/id-([a-zA-Z0-9-]+)$/);
    if (!idMatch) {
      console.error('Could not extract valid ID from seoUrl:', seoUrl);
      return null;
    }

    const id = idMatch[1];
    console.log('Fetching article with ID:', id);

    // Try to get from local cache first
    const cacheKey = `article_${id}`;
    const cachedArticle = getLocalCache<Article>(cacheKey, ARTICLE_CACHE_DURATION);
    if (cachedArticle) {
      return cachedArticle;
    }

    // If not in cache, fetch from API
    const { data } = await api.get<Article>(`/articles/${id}`);
    
    // Cache the article locally
    if (data) {
      setLocalCache<Article>(cacheKey, data);
    }
    
    return data;
  } catch (error) {
    console.error('Error fetching article:', error);
    return null;
  }
} 