import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { NewsList } from './NewsList';
import { Article } from '../types/article';

// Banner Ad placeholder component
const BannerAd = () => (
  <div className="w-full h-24 bg-[#161B22] rounded-lg flex items-center justify-center border border-gray-800 my-8">
    <span className="text-gray-400">Advertisement Banner</span>
  </div>
);

// Map URL-friendly slugs to actual categories
const CATEGORY_SLUGS: Record<string, string> = {
  'bitcoin': 'BITCOIN',
  'ethereum': 'ETHEREUM',
  'defi': 'DEFI',
  'nft-gaming': 'NFT & GAMING',
  'mining': 'MINING',
  'regulation': 'REGULATION',
  'web3': 'WEB3',
  'metaverse': 'METAVERSE'
};

export const SimpleCategoryPage = () => {
  const { categorySlug } = useParams<{ categorySlug: string }>();
  const category = CATEGORY_SLUGS[categorySlug?.toLowerCase() ?? ''] ?? '';
  
  if (!category) {
    return (
      <div className="min-h-screen flex flex-col bg-[#0D1117]">
        <div className="text-center py-12">
          <h1 className="text-2xl font-bold text-red-500">Category Not Found</h1>
          <p className="mt-4 text-gray-400">The requested category does not exist.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-[#0D1117]">
      {/* Main Content Area */}
      <div className="flex-grow">
        <main className="max-w-6xl mx-auto px-4 py-8">
          {/* Top Banner Ad */}
          <BannerAd />
          
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-white">
              {category} News
            </h1>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {/* Main Content */}
            <div className="lg:col-span-3">
              <NewsList category={category} grid />
              
              {/* Bottom Banner Ad */}
              <BannerAd />
            </div>
            
            {/* Sidebar */}
            <div className="space-y-8">
              {/* Sidebar Banner Ad */}
              <BannerAd />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}; 