import { useEffect, useRef } from 'react';

interface AdUnitProps {
  adSlot: string;
  adFormat: 'auto' | 'rectangle' | 'horizontal' | 'vertical';
  className?: string;
}

interface AdConfig {
  google_ad_slot: string;
  google_ad_format: string;
}

interface AdsenseStatus {
  loaded?: boolean;
}

// Extend window interface with proper AdSense types
declare global {
  interface Window {
    adsbygoogle: Array<AdConfig | AdsenseStatus> & {
      push: (params: AdConfig) => void;
    };
  }
}

export const AdUnit = ({ adSlot, adFormat, className = '' }: AdUnitProps) => {
  const adRef = useRef<HTMLDivElement>(null);
  const initAttempts = useRef(0);

  useEffect(() => {
    const validateAdSlot = () => {
      // Validate ad slot format (should be numbers only)
      if (!/^\d+$/.test(adSlot)) {
        console.error(`Invalid ad slot format: ${adSlot}. Ad slots should contain only numbers.`);
        return false;
      }
      return true;
    };

    const checkAdsenseStatus = () => {
      const status = window.adsbygoogle?.[0] as AdsenseStatus;
      return status?.loaded === true;
    };

    const initAd = () => {
      try {
        if (!validateAdSlot()) return;
        
        if (window.adsbygoogle && adRef.current) {
          console.log(`Initializing ad unit: ${adSlot} with format: ${adFormat}`);
          
          // For new AdSense accounts, we need to ensure proper initialization
          if (!window.adsbygoogle.length) {
            window.adsbygoogle = window.adsbygoogle || [];
          }
          
          const adConfig: AdConfig = {
            google_ad_slot: adSlot,
            google_ad_format: adFormat
          };
          
          window.adsbygoogle.push(adConfig);
          console.log(`Ad unit ${adSlot} initialization pushed to queue`);
          
          // Log additional debug info for new accounts
          console.log('AdSense Debug Info:', {
            accountStatus: checkAdsenseStatus(),
            queueLength: window.adsbygoogle.length,
            adUnitConfig: adConfig,
            windowLocation: window.location.href
          });
        } else {
          console.warn(`AdSense not ready for slot ${adSlot}. Window.adsbygoogle:`, window.adsbygoogle);
        }
      } catch (error) {
        console.error('Error initializing AdSense ad unit:', {
          error,
          slot: adSlot,
          format: adFormat,
          attempts: initAttempts.current,
          pageUrl: window.location.href,
          adsensePresent: !!window.adsbygoogle
        });
      }
    };

    // Try to initialize immediately if script is already loaded
    if (checkAdsenseStatus()) {
      console.log(`AdSense already loaded, initializing ad unit ${adSlot}`);
      initAd();
    } else {
      console.log(`Waiting for AdSense to load for ad unit ${adSlot}`);
      // Wait for script to load
      const observer = new MutationObserver((_, obs) => {
        if (checkAdsenseStatus()) {
          console.log(`AdSense loaded via observer for ad unit ${adSlot}`);
          initAd();
          obs.disconnect();
        } else {
          initAttempts.current++;
          if (initAttempts.current > 5) {
            console.error(`Failed to initialize ad unit ${adSlot} after 5 attempts. This is normal for newly activated AdSense accounts.`);
            obs.disconnect();
          }
        }
      });

      observer.observe(document, {
        childList: true,
        subtree: true
      });

      // Cleanup
      return () => {
        console.log(`Cleaning up observer for ad unit ${adSlot}`);
        observer.disconnect();
      };
    }
  }, [adSlot, adFormat]);

  return (
    <div ref={adRef} className={`adunit ${className}`}>
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-8150152878665321"
        data-ad-slot={adSlot}
        data-ad-format={adFormat}
        data-full-width-responsive="true"
      />
    </div>
  );
}; 