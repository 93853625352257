import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { fetchNews, Article, NewsResponse } from '../services/newsApi';
import { NewsCard } from './NewsCard';
import { HeroCarousel } from './HeroCarousel';
import { Link } from 'react-router-dom';
import { format, formatDistanceToNow, isAfter, parseISO } from 'date-fns';

const UPDATE_INTERVAL = 5 * 60 * 1000; // 5 minutes

interface NewsListProps {
  category?: string;
  limit?: number;
  compact?: boolean;
  featured?: boolean;
  grid?: boolean;
  title?: string;
  showLastUpdate?: boolean;
}

const LastUpdateTime = ({ lastUpdate }: { lastUpdate: Date }) => {
  const [timeAgo, setTimeAgo] = useState(formatDistanceToNow(lastUpdate, { addSuffix: true }));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(formatDistanceToNow(lastUpdate, { addSuffix: true }));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [lastUpdate]);

  return (
    <div className="text-sm text-gray-500">
      Updated {timeAgo}
    </div>
  );
};

const CompactArticle = ({ article }: { article: Article }) => (
  <Link to={`/article/${article.seoUrl}`} className="block py-2 group">
    <div className="flex items-start space-x-3">
      <div className="w-20 h-20 rounded overflow-hidden flex-shrink-0">
        <img 
          src={article.imageUrl} 
          alt={article.title}
          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>
      <div className="flex-1">
        <h3 className="text-sm font-medium text-gray-200 group-hover:text-blue-400 line-clamp-2 transition-colors">
          {article.title}
        </h3>
        <div className="text-xs text-gray-500 mt-1">
          {article.source} • {format(new Date(article.publishedAt), 'MMM d, yyyy')}
        </div>
      </div>
    </div>
  </Link>
);

export const NewsList = ({ 
  category, 
  limit,
  compact = false,
  featured = false,
  grid = false,
  title,
  showLastUpdate = false
}: NewsListProps) => {
  const { data, isLoading, isError } = useQuery<NewsResponse>({
    queryKey: ['news', category],
    queryFn: () => fetchNews(category),
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
    cacheTime: 24 * 60 * 60 * 1000, // Keep in memory for 24 hours
    retry: 2
  });

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="space-y-4">
          {Array.from({ length: limit || 5 }).map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="h-48 bg-gray-800 rounded-lg mb-2" />
              <div className="h-4 bg-gray-800 rounded w-3/4 mb-1" />
              <div className="h-3 bg-gray-800 rounded w-1/2" />
            </div>
          ))}
        </div>
      );
    }

    if (isError) {
      return (
        <div className="text-red-400 text-sm p-4 rounded bg-red-900/20">
          Unable to load articles at this time. Please try again later.
        </div>
      );
    }

    if (!data?.articles?.length) {
      return (
        <div className="text-gray-400 text-sm p-4 rounded bg-gray-800/20">
          No articles found for this category.
        </div>
      );
    }

    // Check if an article is new (published after the last cached timestamp)
    const isNewArticle = (article: Article) => {
      if (data.isFromCache) return false;
      const articleDate = parseISO(article.publishedAt);
      const cacheDate = parseISO(data.timestamp);
      return isAfter(articleDate, cacheDate);
    };

    const articles = limit ? data.articles.slice(0, limit) : data.articles;

    if (compact) {
      return (
        <div className="space-y-1 divide-y divide-gray-800">
          {articles.map((article) => (
            <div key={article.id} className="relative">
              <CompactArticle article={article} />
              {isNewArticle(article) && (
                <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs font-medium px-2 py-1 rounded">
                  New
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }

    if (featured) {
      return <HeroCarousel articles={articles} />;
    }

    return (
      <div className={grid ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4" : "space-y-6"}>
        {articles.map((article) => (
          <div key={article.id} className="relative">
            <NewsCard article={article} />
            {isNewArticle(article) && (
              <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs font-medium px-2 py-1 rounded">
                New
              </div>
            )}
          </div>
        ))}
        {data.isFromCache && showLastUpdate && (
          <div className="text-xs text-gray-500 text-center pt-2">
            Last updated {format(parseISO(data.timestamp), 'MMM d, h:mm a')}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {title && <h2 className="text-2xl font-bold text-white mb-4">{title}</h2>}
      {renderContent()}
    </div>
  );
}; 