export function generateSeoUrl(title: string, id: string): string {
  // Clean the title for URL
  const seoTitle = title
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
    .trim(); // Remove leading/trailing spaces

  return `${seoTitle}-id-${id}`;
}

export function extractIdFromSeoUrl(seoUrl: string): string {
  // Match the ID format at the end of the URL
  const matches = seoUrl.match(/id-((?:cointelegraph|decrypt|coindesk|the-block|crypto-briefing|luxor-mining)-[a-z0-9]+-[a-zA-Z0-9]{6})$/);
  if (!matches) {
    console.error('Invalid URL format:', seoUrl);
    throw new Error('Invalid article URL format');
  }
  return matches[1];
}

export function generateMetaTags(article: {
  title: string;
  description: string;
  imageUrl: string;
  category: string;
  publishedAt: string;
  source: string;
}): Record<string, string> {
  return {
    'title': `${article.title} | Aderlas Crypto News`,
    'description': article.description,
    'og:title': article.title,
    'og:description': article.description,
    'og:image': article.imageUrl,
    'og:type': 'article',
    'article:published_time': new Date(article.publishedAt).toISOString(),
    'article:section': article.category,
    'article:publisher': 'Aderlas',
    'twitter:card': 'summary_large_image',
    'twitter:title': article.title,
    'twitter:description': article.description,
    'twitter:image': article.imageUrl
  };
} 