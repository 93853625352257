import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { NewsList } from './NewsList';
import { Article } from '../types/article';
import { TrendingVideos } from './TrendingVideos';
import { AdUnit } from './AdUnit';

// Map URL-friendly slugs to actual categories
const CATEGORY_SLUGS: Record<string, string> = {
  'bitcoin': 'BITCOIN',
  'ethereum': 'ETHEREUM',
  'defi': 'DEFI',
  'nft-gaming': 'NFT & GAMING',
  'mining': 'MINING',
  'regulation': 'REGULATION',
  'web3': 'WEB3',
  'metaverse': 'METAVERSE'
};

// Map categories to SEO descriptions
const CATEGORY_DESCRIPTIONS: Record<string, string> = {
  'BITCOIN': 'Latest Bitcoin news, price analysis, and developments in the Bitcoin ecosystem.',
  'ETHEREUM': 'Stay updated with Ethereum news, smart contract developments, and network upgrades.',
  'DEFI': 'Explore the latest in decentralized finance, protocols, and DeFi innovations.',
  'NFT & GAMING': 'Breaking news and trends in NFTs, digital collectibles, and blockchain gaming.',
  'MINING': 'Latest updates on cryptocurrency mining, hardware, and mining operations.',
  'REGULATION': 'Cryptocurrency regulation updates, policy changes, and legal developments.',
  'WEB3': 'News and insights about Web3 technology, dApps, and decentralized internet.',
  'METAVERSE': 'Updates on metaverse projects, virtual worlds, and digital reality platforms.'
};

export const CategoryPage = () => {
  const { categorySlug } = useParams<{ categorySlug: string }>();
  const category = CATEGORY_SLUGS[categorySlug?.toLowerCase() ?? ''] ?? '';
  
  const { data: articles, isLoading, isError } = useQuery<Article[]>({
    queryKey: ['articles', category],
    queryFn: async () => {
      const response = await fetch(`/api/news/${encodeURIComponent(category)}`);
      if (!response.ok) {
        throw new Error(`Error fetching ${category} articles: ${response.statusText}`);
      }
      const data = await response.json();
      console.log(`[DEBUG] Frontend received ${data?.length || 0} articles for category ${category}`);
      return Array.isArray(data) ? data : [];
    },
    enabled: !!category,
    staleTime: 300000, // 5 minutes
    retry: 2
  });

  if (!category) {
    return (
      <div className="min-h-screen flex flex-col bg-[#0D1117]">
        <div className="text-center py-12">
          <h1 className="text-2xl font-bold text-red-500">Category Not Found</h1>
          <p className="mt-4 text-gray-400">The requested category does not exist.</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${category.charAt(0) + category.slice(1).toLowerCase()} News - Aderlas`}</title>
        <meta name="description" content={CATEGORY_DESCRIPTIONS[category]} />
        <meta property="og:title" content={`${category.charAt(0) + category.slice(1).toLowerCase()} News - Aderlas`} />
        <meta property="og:description" content={CATEGORY_DESCRIPTIONS[category]} />
      </Helmet>

      <div className="min-h-screen flex flex-col bg-[#0D1117]">
        {/* Main Content Area */}
        <div className="flex-grow">
          <main className="max-w-6xl mx-auto px-4 py-8">
            {/* Top Banner Ad */}
            <div className="mb-8">
              <AdUnit 
                adSlot="2345678901"
                adFormat="horizontal"
                className="w-full h-24"
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
              {/* Main Content */}
              <div className="lg:col-span-3">
                {/* Featured Articles */}
                <section className="mb-8">
                  {isLoading ? (
                    <div className="animate-pulse space-y-4">
                      {[...Array(3)].map((_, i) => (
                        <div key={i} className="bg-gray-800 h-48 rounded-lg" />
                      ))}
                    </div>
                  ) : isError ? (
                    <div className="text-red-400 text-center py-8">
                      Failed to load articles. Please try again later.
                    </div>
                  ) : !articles?.length ? (
                    <div className="text-gray-400 text-center py-8">
                      No articles found for this category.
                    </div>
                  ) : (
                    <>
                      {/* Featured Articles */}
                      <div className="mb-8">
                        <NewsList 
                          articles={articles.slice(0, 3)} 
                          showFeatured={true}
                        />
                      </div>
                      
                      {/* Middle Banner Ad */}
                      <div className="my-8">
                        <AdUnit 
                          adSlot="3456789012"
                          adFormat="horizontal"
                          className="w-full h-24"
                        />
                      </div>

                      {/* Regular Articles Grid */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <NewsList 
                          articles={articles.slice(3)} 
                          showFeatured={false}
                        />
                      </div>

                      {/* Bottom Banner Ad */}
                      <div className="mt-8">
                        <AdUnit 
                          adSlot="4567890123"
                          adFormat="horizontal"
                          className="w-full h-24"
                        />
                      </div>
                    </>
                  )}
                </section>
              </div>

              {/* Sidebar */}
              <div className="space-y-8">
                {/* Sidebar Ad */}
                <div className="mb-8">
                  <AdUnit 
                    adSlot="5678901234"
                    adFormat="vertical"
                    className="w-full h-[600px]"
                  />
                </div>

                {/* Latest Headlines */}
                <section className="bg-[#161B22] rounded-lg">
                  <h2 className="text-lg font-bold p-4 border-b border-gray-800 uppercase">Latest Headlines</h2>
                  <div className="p-4">
                    <NewsList compact limit={5} />
                  </div>
                </section>

                {/* Trending Videos */}
                <section className="bg-[#161B22] rounded-lg">
                  <h2 className="text-lg font-bold p-4 border-b border-gray-800 uppercase">Trending Videos</h2>
                  <div className="p-4">
                    <TrendingVideos />
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}; 