import { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { Article, fetchArticle } from '../services/newsApi';
import { extractIdFromSeoUrl } from '../utils/urlUtils';
import { Header } from './Header';
import { useQuery } from '@tanstack/react-query';
import { AdUnit } from './AdUnit';

// Ad component for article pages
const ArticleAd = ({ position }: { position: 'top' | 'middle' | 'bottom' }) => {
  const adSlots = {
    top: '4567890123',
    middle: '5678901234',
    bottom: '6789012345'
  };

  return (
    <div className="my-8">
      <AdUnit 
        adSlot={adSlots[position]}
        adFormat="horizontal"
        className="w-full h-24"
      />
    </div>
  );
};

export const ArticlePage = () => {
  const { seoUrl } = useParams<{ seoUrl: string }>();
  const navigate = useNavigate();
  const id = seoUrl ? extractIdFromSeoUrl(seoUrl) : null;

  const { data: article, isLoading, error: queryError } = useQuery({
    queryKey: ['article', id],
    queryFn: () => fetchArticle(seoUrl!),
    enabled: !!seoUrl
  });

  // Track article view
  useEffect(() => {
    if (article) {
      window.gtag('event', 'article_view', {
        article_id: seoUrl,
        article_title: article.title,
        article_category: article.category,
        source: article.source
      });
    }
  }, [article, seoUrl]);

  // Track when user reaches end of article
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
    if (bottom && article) {
      window.gtag('event', 'article_complete', {
        article_id: seoUrl,
        article_title: article.title
      });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#0D1117]">
        <div className="max-w-4xl mx-auto px-4 py-8">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-700 rounded w-3/4"></div>
            <div className="h-4 bg-gray-700 rounded w-1/4"></div>
            <div className="h-64 bg-gray-700 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (queryError || !article) {
    return (
      <div className="min-h-screen bg-[#0D1117]">
        <div className="max-w-4xl mx-auto px-4 py-8 text-center">
          <h1 className="text-2xl font-bold text-red-500">Article Not Found</h1>
          <p className="mt-4 text-gray-400">The requested article could not be found.</p>
          <Link to="/" className="mt-6 inline-block text-blue-400 hover:text-blue-300">
            Return to Home
          </Link>
        </div>
      </div>
    );
  }

  const formattedDate = format(new Date(article.publishedAt), 'MMM d, yyyy h:mm a');
  const metaTags = {
    'description': article.description,
    'og:title': article.title,
    'og:description': article.description,
    'og:image': article.imageUrl,
    'og:type': 'article',
    'og:url': `https://aderlas.com/article/${article.seoUrl}`,
    'twitter:card': 'summary_large_image',
    'twitter:title': article.title,
    'twitter:description': article.description,
    'twitter:image': article.imageUrl,
    'article:published_time': article.publishedAt,
    'article:section': article.category,
    'article:publisher': 'Aderlas'
  };

  return (
    <div className="min-h-screen bg-[#0D1117]">
      <Helmet>
        <title>{`${article.title} - Aderlas`}</title>
        {Object.entries(metaTags).map(([name, content]) => (
          <meta key={name} name={name} content={content} />
        ))}
        <link rel="canonical" href={`https://aderlas.com/article/${article.seoUrl}`} />
      </Helmet>

      {/* Top Ad */}
      <div className="max-w-4xl mx-auto px-4 py-4">
        <ArticleAd position="top" />
      </div>

      <main className="max-w-4xl mx-auto px-4 py-8">
        {/* Breadcrumb navigation */}
        <nav className="mb-4">
          <ol className="flex items-center space-x-2 text-sm">
            <li>
              <Link to="/" className="text-blue-400 hover:text-blue-300">
                Home
              </Link>
            </li>
            <li className="text-gray-500">/</li>
            <li className="text-gray-400 truncate max-w-[300px]">
              {article?.title || 'Article'}
            </li>
          </ol>
        </nav>

        <article className="bg-[#161B22] rounded-lg overflow-hidden">
          {/* Article Header */}
          <div className="p-6 border-b border-gray-800">
            <div className="space-y-4">
              <span className="inline-block rounded px-2 py-1 text-xs font-medium uppercase bg-blue-600 text-white">
                {article.category}
              </span>
              <h1 className="text-3xl font-bold text-gray-100 leading-tight">
                {article.title}
              </h1>
              <div className="flex items-center space-x-4 text-sm text-gray-400">
                <span>{article.source}</span>
                <span>•</span>
                <time dateTime={article.publishedAt}>{formattedDate}</time>
              </div>
            </div>
          </div>

          {/* Article Content */}
          <div className="p-6">
            {/* Featured Image */}
            {article.imageUrl && (
              <div className="mb-6">
                <img
                  src={article.imageUrl}
                  alt={article.title}
                  className="w-full h-auto rounded-lg"
                />
              </div>
            )}

            {/* Article Text */}
            <div className="prose prose-invert max-w-none">
              <p className="text-gray-300 text-lg leading-relaxed mb-6">
                {article.description}
              </p>

              {/* Middle Ad */}
              <ArticleAd position="middle" />

              <div 
                className="text-gray-300 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: article.content }}
              />

              {/* Source Link */}
              <div className="mt-8 mb-8">
                <a
                  href={article.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-white transition-colors"
                >
                  Read Full Article at {article.source}
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </div>

              {/* Bottom Ad */}
              <ArticleAd position="bottom" />
            </div>
          </div>
        </article>

        {/* Related Articles Section */}
        <section className="mt-8">
          <h2 className="text-xl font-bold text-gray-200 mb-4">Related Articles</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* Placeholder for related articles */}
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-[#161B22] border-t border-gray-800 mt-8">
        <div className="max-w-4xl mx-auto px-4 py-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">About</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <Link to="/about" className="text-gray-300 hover:text-white">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-gray-300 hover:text-white">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">Legal</h3>
              <ul className="mt-4 space-y-2">
                <li>
                  <Link to="/privacy" className="text-gray-300 hover:text-white">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-300 hover:text-white">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-800 pt-8">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Aderlas. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}; 