import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MainLayout } from './components/MainLayout';
import { CategoryPage } from './components/CategoryPage';
import { SimpleCategoryPage } from './components/SimpleCategoryPage';
import { ArticlePage } from './components/ArticlePage';
import { AdsReferencePage } from './components/AdsReferencePage';
import Layout from './components/Layout';
import { useEffect } from 'react';
import { ScrollToTop } from './components/ScrollToTop';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2
    }
  }
});

interface AdsbyGoogle {
  push(obj: object): void;
}

declare global {
  interface Window {
    gtag: (type: string, action: string, params: object) => void;
    adsbygoogle: AdsbyGoogle[];
  }
}

// Separate component for route tracking
const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route changes
    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      page_title: document.title
    });
  }, [location]);

  return null;
};

// Main app component
export const App = () => {
  useEffect(() => {
    // Add AdSense script to head if not already present
    if (!document.querySelector('script[src*="adsbygoogle.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8150152878665321';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    }
  }, []);

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ScrollToTop />
          <RouteTracker />
          <Helmet>
            <meta name="google-adsense-account" content="ca-pub-8150152878665321" />
          </Helmet>
          <Layout>
            <Routes>
              <Route path="/" element={<MainLayout />} />
              <Route path="/article/:seoUrl" element={<ArticlePage />} />
              <Route path="/category/:categorySlug" element={<CategoryPage />} />
              <Route path="/c/:categorySlug" element={<SimpleCategoryPage />} />
              <Route path="/adsreference" element={<AdsReferencePage />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  );
};
