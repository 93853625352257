import { useEffect, useRef } from 'react';
import { createChart, ColorType, IChartApi, ISeriesApi } from 'lightweight-charts';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface CryptoPrice {
  symbol: string;
  price: number;
  change24h: number;
  sparklineData: { time: string; value: number }[];
}

interface BinanceTicker {
  symbol: string;
  lastPrice: string;
  priceChangePercent: string;
}

interface BinanceKline {
  0: number;  // Open time
  4: string;  // Close price
}

const TOP_CRYPTOS = [
  'BTCUSDT', 'ETHUSDT', 'BNBUSDT', 'SOLUSDT', 
  'ADAUSDT', 'DOGEUSDT', 'DOTUSDT', 'MATICUSDT',
  'LINKUSDT', 'AVAXUSDT'
];

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 8
  }).format(price);
};

// Fetch initial price data
const fetchInitialPrices = async () => {
  try {
    const response = await fetch('https://api.binance.com/api/v3/ticker/24hr?symbols=' + 
      JSON.stringify(TOP_CRYPTOS));
    if (!response.ok) throw new Error('Failed to fetch prices');
    const data = await response.json();
    
    return data.map((ticker: BinanceTicker) => ({
      symbol: ticker.symbol,
      price: parseFloat(ticker.lastPrice),
      change24h: parseFloat(ticker.priceChangePercent),
      sparklineData: []
    }));
  } catch (error) {
    console.error('Error fetching initial prices:', error);
    return [];
  }
};

// Fetch historical klines for sparkline
const fetchSparklineData = async (symbol: string) => {
  try {
    const endTime = Date.now();
    const startTime = endTime - (24 * 60 * 60 * 1000); // 24 hours ago
    
    const response = await fetch(
      `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=5m&startTime=${startTime}&endTime=${endTime}`
    );
    
    if (!response.ok) throw new Error('Failed to fetch klines');
    const data = await response.json();
    
    return data.map((kline: BinanceKline) => ({
      time: formatDate(kline[0]),
      value: parseFloat(kline[4]) // Close price
    }));
  } catch (error) {
    console.error(`Error fetching sparkline data for ${symbol}:`, error);
    return [];
  }
};

export const CryptoTicker = () => {
  const queryClient = useQueryClient();
  const chartContainers = useRef<Map<string, HTMLDivElement>>(new Map());
  const charts = useRef<Map<string, { chart: IChartApi; series: ISeriesApi<'Line'> }>>(new Map());
  const wsRef = useRef<WebSocket | null>(null);

  // Fetch initial prices and sparkline data
  const { data: prices = [] } = useQuery<CryptoPrice[]>({
    queryKey: ['cryptoPrices'],
    queryFn: async () => {
      const initialPrices = await fetchInitialPrices();
      
      // Fetch sparkline data for each symbol
      const pricesWithSparklines = await Promise.all(
        initialPrices.map(async (price: CryptoPrice) => ({
          ...price,
          sparklineData: await fetchSparklineData(price.symbol)
        }))
      );
      
      return pricesWithSparklines;
    },
    staleTime: 30000, // 30 seconds
    refetchInterval: 30000,
  });

  useEffect(() => {
    const connectWebSocket = () => {
      if (wsRef.current?.readyState === WebSocket.OPEN) return;

      wsRef.current = new WebSocket('wss://stream.binance.com:9443/ws');
      
      const subscribeMsg = {
        method: 'SUBSCRIBE',
        params: [
          ...TOP_CRYPTOS.map(symbol => `${symbol.toLowerCase()}@ticker`),
          ...TOP_CRYPTOS.map(symbol => `${symbol.toLowerCase()}@kline_5m`)
        ],
        id: 1
      };
      
      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        wsRef.current?.send(JSON.stringify(subscribeMsg));
      };
      
      wsRef.current.onclose = () => {
        console.log('WebSocket closed, attempting to reconnect...');
        setTimeout(connectWebSocket, 3000);
      };
      
      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      
      wsRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        
        if (data.e === '24hrTicker') {
          queryClient.setQueryData(['cryptoPrices'], (prev: CryptoPrice[] = []) => {
            const index = prev.findIndex(p => p.symbol === data.s);
            const newPrice = {
              symbol: data.s,
              price: parseFloat(data.c),
              change24h: parseFloat(data.p),
              sparklineData: prev[index]?.sparklineData || []
            };
            
            if (index === -1) {
              return [...prev, newPrice];
            }
            
            const newPrices = [...prev];
            newPrices[index] = newPrice;
            return newPrices;
          });
        }
        
        if (data.e === 'kline') {
          queryClient.setQueryData(['cryptoPrices'], (prev: CryptoPrice[] = []) => {
            const index = prev.findIndex(p => p.symbol === data.s);
            if (index === -1) return prev;
            
            const formattedTime = formatDate(data.k.t);
            const existingData = prev[index].sparklineData;
            
            // Remove any existing data point with the same timestamp
            const filteredData = existingData.filter(d => d.time !== formattedTime);
            
            // Add new data point and ensure ascending order
            const newSparklineData = [...filteredData, { 
              time: formattedTime,
              value: parseFloat(data.k.c)
            }]
            .sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime())
            .slice(-100);
            
            const newPrices = [...prev];
            newPrices[index] = {
              ...newPrices[index],
              sparklineData: newSparklineData
            };
            return newPrices;
          });
        }
      };
    };

    connectWebSocket();

    // Capture the current charts ref value for cleanup
    const currentCharts = charts.current;

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      currentCharts.forEach(({ chart }) => chart.remove());
      currentCharts.clear();
    };
  }, [queryClient]);

  useEffect(() => {
    prices.forEach(price => {
      const container = chartContainers.current.get(price.symbol);
      if (!container) return;

      if (!charts.current.has(price.symbol)) {
        const chart = createChart(container, {
          width: 80,
          height: 30,
          layout: {
            background: { type: ColorType.Solid, color: 'transparent' },
            textColor: '#d1d5db',
          },
          grid: {
            vertLines: { visible: false },
            horzLines: { visible: false },
          },
          rightPriceScale: { 
            visible: true,
            borderVisible: false,
          },
          timeScale: { 
            visible: true,
            fixLeftEdge: true,
            fixRightEdge: true,
          },
          crosshair: { 
            mode: 0,
            horzLine: { visible: true },
            vertLine: { visible: true },
          },
          handleScroll: false,
          handleScale: false,
        });

        const series = chart.addLineSeries({
          color: price.change24h >= 0 ? '#10b981' : '#ef4444',
          lineWidth: 1,
          priceLineVisible: true,
          lastValueVisible: true,
          crosshairMarkerVisible: true,
          baseLineVisible: true,
        });

        charts.current.set(price.symbol, { chart, series });
        if (price.sparklineData.length > 0) {
          try {
            series.setData(price.sparklineData);
          } catch (error) {
            console.error('Error setting chart data:', error);
          }
        }
      } else {
        const { series } = charts.current.get(price.symbol)!;
        if (price.sparklineData.length > 0) {
          try {
            series.setData(price.sparklineData);
          } catch (error) {
            console.error('Error updating chart data:', error);
          }
        }
        series.applyOptions({
          color: price.change24h >= 0 ? '#10b981' : '#ef4444',
          priceLineVisible: false,
          lastValueVisible: false,
        });
      }
    });
  }, [prices]);

  return (
    <div className="bg-gray-900 p-4 overflow-hidden">
      <div className="relative w-full">
        <div className="animate-scroll flex whitespace-nowrap">
          {/* Original set of prices */}
          {prices.map((price) => (
            <div key={price.symbol} className="inline-flex items-center space-x-3 p-2 mx-2">
              <div className="min-w-[80px]">
                <div className="text-sm font-medium text-gray-200">{price.symbol.replace('USDT', '')}</div>
                <div className="text-xs text-gray-400">${formatPrice(price.price)}</div>
              </div>
              <div 
                ref={el => el && chartContainers.current.set(price.symbol, el)}
                className="w-[80px] h-[30px]"
              />
              <div className={`text-xs ${price.change24h >= 0 ? 'text-green-500' : 'text-red-500'} min-w-[60px] text-right`}>
                {price.change24h >= 0 ? '+' : ''}{price.change24h.toFixed(2)}%
              </div>
            </div>
          ))}
          {/* Duplicate set for seamless loop */}
          {prices.map((price) => (
            <div key={`${price.symbol}-dup`} className="inline-flex items-center space-x-3 p-2 mx-2">
              <div className="min-w-[80px]">
                <div className="text-sm font-medium text-gray-200">{price.symbol.replace('USDT', '')}</div>
                <div className="text-xs text-gray-400">${formatPrice(price.price)}</div>
              </div>
              <div className="w-[80px] h-[30px]" />
              <div className={`text-xs ${price.change24h >= 0 ? 'text-green-500' : 'text-red-500'} min-w-[60px] text-right`}>
                {price.change24h >= 0 ? '+' : ''}{price.change24h.toFixed(2)}%
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};