import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Toast } from './Toast';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'http://localhost:3000',
});

interface SubscribeResponse {
  success: boolean;
  message: string;
}

interface ToastState {
  show: boolean;
  message: string;
  type: 'success' | 'error';
}

export const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [toast, setToast] = useState<ToastState>({
    show: false,
    message: '',
    type: 'success'
  });
  
  const { mutate: subscribe, isLoading } = useMutation<
    SubscribeResponse,
    Error,
    string
  >({
    mutationFn: async (email: string) => {
      console.log('[Newsletter] Attempting to subscribe email:', email);
      console.log('[Newsletter] Using API URL:', api.defaults.baseURL);
      
      try {
        const { data } = await api.post<SubscribeResponse>('/subscribe', { email });
        console.log('[Newsletter] Subscription response:', data);
        return data;
      } catch (err) {
        console.error('[Newsletter] Subscription error:', err);
        if (axios.isAxiosError(err)) {
          console.error('[Newsletter] Response data:', err.response?.data);
          throw new Error(err.response?.data?.message || 'Failed to subscribe to newsletter');
        }
        throw err;
      }
    },
    onSuccess: (data) => {
      console.log('[Newsletter] Subscription successful:', data);
      setEmail(''); // Clear form on success
      setToast({
        show: true,
        message: 'Successfully subscribed to newsletter!',
        type: 'success'
      });
    },
    onError: (err) => {
      console.error('[Newsletter] Mutation error:', err);
      setToast({
        show: true,
        message: err.message || 'Failed to subscribe. Please try again.',
        type: 'error'
      });
    }
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('[Newsletter] Form submitted with email:', email);
    if (email) {
      subscribe(email);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex items-center justify-between">
        <span className="text-sm whitespace-nowrap">Get the Aderlas daily crypto newsletter</span>
        <div className="flex items-center space-x-2 ml-4">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="bg-gray-800 border border-gray-700 rounded px-3 py-1 text-sm focus:outline-none focus:border-blue-500 w-full max-w-xs"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !email}
            className={`text-sm whitespace-nowrap px-3 py-1 rounded transition-colors ${
              isLoading || !email
                ? 'bg-gray-600 cursor-not-allowed'
                : 'bg-blue-600 hover:bg-blue-700'
            }`}
          >
            {isLoading ? 'Subscribing...' : 'Subscribe'}
          </button>
        </div>
      </form>
      
      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(prev => ({ ...prev, show: false }))}
        />
      )}
    </>
  );
}; 