import { Link } from 'react-router-dom';

interface AdFormat {
  name: string;
  width: number;
  height: number;
  description: string;
  location: string;
}

const AD_FORMATS: AdFormat[] = [
  {
    name: 'Premium Header Banner',
    width: 1200,
    height: 96,
    description: 'High-visibility banner at the top of every page. Collapsible with toggle button.',
    location: 'Top of all pages'
  },
  {
    name: 'Main Content Banner',
    width: 864,
    height: 96,
    description: 'Appears between news categories on the homepage and between article sections.',
    location: 'Homepage and article pages'
  },
  {
    name: 'Article Banner',
    width: 768,
    height: 96,
    description: 'Integrated within article content for maximum engagement.',
    location: 'Article pages (top, middle, and bottom)'
  },
  {
    name: 'Sidebar Banner',
    width: 288,
    height: 96,
    description: 'Compact banner perfect for targeted promotions.',
    location: 'Right sidebar on homepage and category pages'
  }
];

const CATEGORIES = [
  { id: 'bitcoin', label: 'BITCOIN' },
  { id: 'ethereum', label: 'ETHEREUM' },
  { id: 'defi', label: 'DEFI' },
  { id: 'nft-gaming', label: 'NFT & GAMING' },
  { id: 'mining', label: 'MINING' },
  { id: 'regulation', label: 'REGULATION' },
  { id: 'web3', label: 'WEB3' },
  { id: 'metaverse', label: 'METAVERSE' }
] as const;

export const AdsReferencePage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-[#0D1117]">
      <main className="flex-grow max-w-4xl mx-auto px-4 py-8">
        <div className="bg-[#161B22] rounded-lg overflow-hidden">
          <div className="p-6 border-b border-gray-800">
            <h1 className="text-3xl font-bold text-gray-100 mb-4">
              Advertising Specifications
            </h1>
            <p className="text-gray-300 text-lg">
              Welcome to Aderlas advertising reference. Below you'll find detailed specifications for all ad formats available on our platform.
            </p>
          </div>
          
          <div className="p-6">
            <div className="grid gap-6">
              {AD_FORMATS.map((format) => (
                <div key={format.name} className="bg-[#1C2128] rounded-lg p-6 border border-gray-800">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold text-gray-100">{format.name}</h2>
                    <span className="text-blue-400 font-mono">
                      {format.width}x{format.height}px
                    </span>
                  </div>
                  
                  <div className="relative mb-4">
                    <div 
                      className="bg-[#2D333B] rounded border border-gray-700 flex items-center justify-center"
                      style={{
                        width: '100%',
                        height: '120px',
                        maxWidth: `${format.width}px`,
                        margin: '0 auto'
                      }}
                    >
                      <div className="text-gray-500 text-sm">
                        {format.width}x{format.height}
                      </div>
                    </div>
                  </div>
                  
                  <div className="space-y-2">
                    <p className="text-gray-300">{format.description}</p>
                    <div className="flex items-center space-x-2 text-sm">
                      <span className="text-gray-500">Location:</span>
                      <span className="text-gray-300">{format.location}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="mt-8 p-6 bg-[#1C2128] rounded-lg border border-gray-800">
              <h2 className="text-xl font-bold text-gray-100 mb-4">Technical Requirements</h2>
              <ul className="space-y-3 text-gray-300">
                <li>• File formats: PNG, JPG, or GIF</li>
                <li>• Maximum file size: 300KB</li>
                <li>• Color space: RGB</li>
                <li>• Resolution: 72 DPI minimum</li>
                <li>• Animation: Maximum 15 seconds (for GIF)</li>
              </ul>
            </div>
            
            <div className="mt-8 p-6 bg-blue-600 bg-opacity-20 rounded-lg border border-blue-500">
              <h2 className="text-xl font-bold text-gray-100 mb-4">Ready to Advertise?</h2>
              <p className="text-gray-300 mb-4">
                Contact our advertising team to discuss placement options and pricing.
              </p>
              <a 
                href="mailto:ads@aderlas.com" 
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                Contact Advertising Team
              </a>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="w-full bg-[#161B22] border-t border-gray-800 mt-16">
        <div className="max-w-6xl mx-auto px-4 py-12">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-8">
            {CATEGORIES.map((category) => (
              <div key={category.id}>
                <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">{category.label}</h3>
                <ul className="mt-4 space-y-2">
                  <li><Link to={`/c/${category.id}`} className="text-gray-300 hover:text-white text-sm">Latest News</Link></li>
                  <li><Link to={`/c/${category.id}`} className="text-gray-300 hover:text-white text-sm">Analysis</Link></li>
                  <li><Link to={`/c/${category.id}`} className="text-gray-300 hover:text-white text-sm">Price Action</Link></li>
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-8 border-t border-gray-800 pt-8 flex justify-between">
            <p className="text-gray-400 text-sm">© {new Date().getFullYear()} Aderlas. All rights reserved.</p>
            <div className="flex space-x-6">
              <Link to="/about" className="text-gray-400 hover:text-white text-sm">About Us</Link>
              <Link to="/privacy" className="text-gray-400 hover:text-white text-sm">Privacy Policy</Link>
              <Link to="/terms" className="text-gray-400 hover:text-white text-sm">Terms of Use</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}; 