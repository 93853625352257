import { useQuery } from '@tanstack/react-query';
import { fetchTrendingCryptoVideos, YouTubeVideo, YouTubeResponse } from '../services/youtubeApi';
import { format, isAfter, parseISO } from 'date-fns';

const formatViewCount = (viewCount: string): string => {
  const count = parseInt(viewCount);
  if (count >= 1000000) {
    return `${(count / 1000000).toFixed(1)}M views`;
  }
  if (count >= 1000) {
    return `${(count / 1000).toFixed(1)}K views`;
  }
  return `${count} views`;
};

export const TrendingVideos = () => {
  const { data, isLoading, isError } = useQuery<YouTubeResponse>({
    queryKey: ['trending-videos'],
    queryFn: fetchTrendingCryptoVideos,
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
    cacheTime: 24 * 60 * 60 * 1000, // Keep in memory for 24 hours
    retry: (failureCount, error) => {
      // Don't retry on quota exceeded
      if (error.message?.includes('quota exceeded')) {
        return false;
      }
      return failureCount < 2;
    },
  });

  if (isLoading) {
    return (
      <div className="space-y-4">
        {Array.from({ length: 5 }).map((_, i) => (
          <div key={i} className="animate-pulse">
            <div className="aspect-video bg-gray-800 rounded-lg mb-2" />
            <div className="h-4 bg-gray-800 rounded w-3/4 mb-1" />
            <div className="h-3 bg-gray-800 rounded w-1/2" />
          </div>
        ))}
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-red-400 text-sm p-4 rounded bg-red-900/20">
        Unable to load videos at this time. Please try again later.
      </div>
    );
  }

  if (!data?.videos?.length) {
    return null;
  }

  // Check if a video is new (published after the last cached timestamp)
  const isNewVideo = (video: YouTubeVideo) => {
    if (data.isFromCache) return false;
    const videoDate = parseISO(video.publishedAt);
    const cacheDate = parseISO(data.timestamp);
    return isAfter(videoDate, cacheDate);
  };

  return (
    <div className="space-y-4">
      {data.videos.map((video) => (
        <a
          key={video.id}
          href={`https://www.youtube.com/watch?v=${video.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="block group"
        >
          <div className="relative aspect-video rounded-lg overflow-hidden mb-2">
            <img
              src={video.thumbnail}
              alt={video.title}
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-black/20 group-hover:bg-black/0 transition-colors" />
            {isNewVideo(video) && (
              <div className="absolute top-2 right-2 bg-blue-500 text-white text-xs font-medium px-2 py-1 rounded">
                New
              </div>
            )}
          </div>
          <h3 className="text-sm font-medium text-gray-200 group-hover:text-blue-400 line-clamp-2 transition-colors">
            {video.title}
          </h3>
          <div className="flex items-center text-xs text-gray-500 mt-1">
            <span>{video.channelTitle}</span>
            <span className="mx-1">•</span>
            <span>{formatViewCount(video.viewCount)}</span>
            <span className="mx-1">•</span>
            <span>{format(new Date(video.publishedAt), 'MMM d, yyyy')}</span>
          </div>
        </a>
      ))}
      {data.isFromCache && (
        <div className="text-xs text-gray-500 text-center pt-2">
          Last updated {format(parseISO(data.timestamp), 'MMM d, h:mm a')}
        </div>
      )}
    </div>
  );
}; 