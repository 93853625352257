import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { CryptoTicker } from './CryptoTicker';
import { NewsletterForm } from './NewsletterForm';

const CATEGORIES = [
  { name: 'BITCOIN', slug: 'bitcoin' },
  { name: 'ETHEREUM', slug: 'ethereum' },
  { name: 'DEFI', slug: 'defi' },
  { name: 'NFT & GAMING', slug: 'nft-gaming' },
  { name: 'MINING', slug: 'mining' },
  { name: 'REGULATION', slug: 'regulation' },
  { name: 'WEB3', slug: 'web3' },
  { name: 'METAVERSE', slug: 'metaverse' }
];

export const Header = () => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';
  const isCategory = location.pathname.startsWith('/category/') || location.pathname.startsWith('/c/');
  const currentCategory = isCategory 
    ? CATEGORIES.find(cat => 
        location.pathname.includes(cat.slug.toLowerCase()) || 
        location.pathname.includes(cat.name.toLowerCase())
      )
    : null;

  useEffect(() => {
    // Add smooth scrolling behavior
    const handleAnchorClick = (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      if (target.hash && isHomepage) {
        e.preventDefault();
        const element = document.querySelector(target.hash);
        if (element) {
          // Get header height for offset
          const header = document.querySelector('header');
          const headerHeight = header ? header.offsetHeight : 0;
          // Add extra padding for visual comfort
          const offset = headerHeight + 24; // 24px extra padding

          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
          
          // Update URL without jumping
          window.history.pushState(null, '', target.hash);
        }
      }
    };

    // Handle initial hash in URL
    const handleInitialHash = () => {
      if (location.hash && isHomepage) {
        const element = document.querySelector(location.hash);
        if (element) {
          setTimeout(() => {
            const header = document.querySelector('header');
            const headerHeight = header ? header.offsetHeight : 0;
            const offset = headerHeight + 24;

            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            });
          }, 100); // Small delay to ensure page is loaded
        }
      }
    };

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    // Handle case when URL already has a hash on page load
    handleInitialHash();

    return () => {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, [isHomepage, location.hash]);

  return (
    <>
      {/* Newsletter Bar - Full Width */}
      <div className="w-full bg-gradient-to-r from-gray-900 to-gray-800 border-b border-gray-700">
        <div className="max-w-6xl mx-auto px-4 py-2">
          <NewsletterForm />
        </div>
      </div>

      {/* Main Header - Full Width */}
      <header className="sticky top-0 z-50 bg-[#0D1117] border-b border-gray-800">
        <CryptoTicker />
        <nav className="max-w-6xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Link to="/" className="flex items-center">
                <img 
                  src="/assets/images/aderlaswhite_new.png" 
                  alt="Aderlas" 
                  className="h-8 w-auto"
                />
              </Link>
              {isCategory && currentCategory && (
                <>
                  <span className="text-gray-500">/</span>
                  <span className="text-gray-200 font-semibold">
                    {currentCategory.name}
                  </span>
                </>
              )}
            </div>
            <div className="hidden md:flex items-center space-x-10">
              {CATEGORIES.map(({ name, slug }) => (
                <Link
                  key={slug}
                  to={`/c/${slug}`}
                  className={`nav-link text-sm whitespace-nowrap ${location.pathname === `/c/${slug}` ? 'active' : ''}`}
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}; 