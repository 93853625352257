import { Link } from 'react-router-dom';
import { Article } from '../services/newsApi';

interface NewsCardProps {
  article: Article;
  compact?: boolean;
  featured?: boolean;
}

export const NewsCard = ({ article, compact }: NewsCardProps) => {
  const formattedDate = new Date(article.publishedAt).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  if (compact) {
    return (
      <Link
        to={`/article/${article.seoUrl}`}
        className="flex items-start gap-4 p-4 hover:bg-gray-800/50 rounded-lg transition-colors"
      >
        <img
          src={article.imageUrl}
          alt={article.title}
          className="w-24 h-24 object-cover rounded"
        />
        <div className="flex-1 min-w-0">
          <h3 className="text-gray-200 font-medium line-clamp-2 mb-2">{article.title}</h3>
          <div className="flex items-center gap-2 text-sm text-gray-400">
            <span>{article.source}</span>
            <span>•</span>
            <time dateTime={article.publishedAt}>{formattedDate}</time>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Link
      to={`/article/${article.seoUrl}`}
      className="block bg-[#161B22] rounded-lg overflow-hidden hover:ring-1 hover:ring-blue-500/50 transition-all"
    >
      <img
        src={article.imageUrl}
        alt={article.title}
        className="w-full aspect-video object-cover"
      />
      <div className="p-4">
        <h2 className="text-gray-200 font-medium text-lg line-clamp-2 mb-2">{article.title}</h2>
        <div className="flex items-center gap-2 text-sm text-gray-400">
          <span>{article.source}</span>
          <span>•</span>
          <time dateTime={article.publishedAt}>{formattedDate}</time>
        </div>
      </div>
    </Link>
  );
}; 