import axios from 'axios';

export interface YouTubeVideo {
  id: string;
  title: string;
  thumbnail: string;
  channelTitle: string;
  publishedAt: string;
  viewCount: string;
}

export interface YouTubeResponse {
  videos: YouTubeVideo[];
  timestamp: string;
  isFromCache?: boolean;
}

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'http://localhost:3000',
});

const CACHE_KEY = 'youtube_trending_cache';
const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours

interface LocalCacheEntry {
  videos: YouTubeVideo[];
  timestamp: string;
  lastFetched: number;
}

// Get cached videos from localStorage
function getLocalCache(): LocalCacheEntry | null {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;
    return JSON.parse(cached);
  } catch (error) {
    console.error('Error reading local cache:', error);
    return null;
  }
}

// Save videos to localStorage
function setLocalCache(data: YouTubeResponse) {
  try {
    const cacheEntry: LocalCacheEntry = {
      videos: data.videos,
      timestamp: data.timestamp,
      lastFetched: Date.now()
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheEntry));
  } catch (error) {
    console.error('Error saving to local cache:', error);
  }
}

export async function fetchTrendingCryptoVideos(): Promise<YouTubeResponse> {
  // First, try to get from local cache
  const cached = getLocalCache();
  const now = Date.now();
  
  // If we have valid cache, return it immediately
  if (cached && (now - cached.lastFetched < CACHE_DURATION)) {
    return {
      ...cached,
      isFromCache: true
    };
  }

  try {
    // Fetch fresh data
    const { data } = await api.get<YouTubeResponse>('/videos/trending');
    
    // Save to local cache
    setLocalCache(data);
    
    return {
      ...data,
      isFromCache: false
    };
  } catch (error) {
    console.error('Error fetching YouTube videos:', error);
    
    // If API fails but we have expired cache, return it as fallback
    if (cached) {
      return {
        ...cached,
        isFromCache: true
      };
    }
    
    // If all else fails, return empty
    return {
      videos: [],
      timestamp: new Date().toISOString(),
      isFromCache: false
    };
  }
}

interface CacheEntry {
  videos: YouTubeVideo[];
  timestamp: number;
}

const YOUTUBE_API_KEY = import.meta.env.VITE_YOUTUBE_API_KEY;
const CRYPTO_CHANNELS = [
  'UCLnQ34ZBSjy2JQjeRudFEDw', // Coinbureau
  'UCqK_GSMbpiV8spgD3ZGloSw', // Coin Telegraph
  'UCMtJYS0PrtiUwlk6zjGDEMA', // Decrypt
  'UCnxrdFPXJMeHru_b4Q_vTPQ', // Bankless
  'UCY0xL8V6NzzFcwzHCgB8orQ', // Crypto Daily
];

// Check cache and return cached data if valid
function getCachedVideos(): YouTubeVideo[] | null {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    const { videos, timestamp }: CacheEntry = JSON.parse(cached);
    const isExpired = Date.now() - timestamp > CACHE_DURATION;

    return isExpired ? null : videos;
  } catch (error) {
    console.error('Error reading cache:', error);
    return null;
  }
}

// Save videos to cache
function cacheVideos(videos: YouTubeVideo[]) {
  try {
    const cacheEntry: CacheEntry = {
      videos,
      timestamp: Date.now(),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheEntry));
  } catch (error) {
    console.error('Error saving to cache:', error);
  }
}

export type { YouTubeVideo }; 