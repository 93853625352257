import { Link } from 'react-router-dom';
import { NewsList } from './NewsList';
import { TrendingVideos } from './TrendingVideos';
import { AdUnit } from './AdUnit';

const CATEGORIES = [
  { id: 'bitcoin', label: 'BITCOIN' },
  { id: 'ethereum', label: 'ETHEREUM' },
  { id: 'defi', label: 'DEFI' },
  { id: 'nft-gaming', label: 'NFT & GAMING' },
  { id: 'mining', label: 'MINING' },
  { id: 'regulation', label: 'REGULATION' },
  { id: 'web3', label: 'WEB3' },
  { id: 'metaverse', label: 'METAVERSE' }
] as const;

// Banner Ad component using AdUnit
const BannerAd = () => (
  <div className="w-full my-8">
    <AdUnit 
      adSlot="2345678901"
      adFormat="horizontal"
      className="w-full h-24"
    />
  </div>
);

// Sidebar Ad component using AdUnit
const SidebarAd = () => (
  <div className="w-full">
    <AdUnit 
      adSlot="3456789012"
      adFormat="vertical"
      className="w-full min-h-[600px]"
    />
  </div>
);

export const MainLayout = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Main Content Area */}
      <div className="flex-grow bg-[#0D1117]">
        {/* Main Content - Centered */}
        <main className="max-w-6xl mx-auto px-4 py-6">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
            {/* Main Content */}
            <div className="lg:col-span-3 space-y-8">
              {/* Featured Article */}
              <section className="space-y-4">
                <div className="relative">
                  <NewsList featured limit={3} />
                </div>
              </section>

              {/* Category Sections with Banner Ads */}
              {CATEGORIES.map((category, index) => (
                <div key={category.id}>
                  {/* Insert banner ad after every third category */}
                  {index > 0 && index % 3 === 0 && <BannerAd />}
                  
                  <section id={category.id}>
                    <div className="flex items-center justify-between mb-4 border-b border-gray-800">
                      <h2 className="text-lg font-bold text-gray-200 uppercase">{category.label}</h2>
                      <Link 
                        to={`/c/${category.id}`} 
                        className="text-blue-400 text-xs hover:text-blue-300"
                      >
                        VIEW ALL
                      </Link>
                    </div>
                    <NewsList category={category.label} limit={4} grid />
                  </section>
                </div>
              ))}
            </div>

            {/* Sidebar */}
            <div className="space-y-8">
              {/* Latest Headlines */}
              <section className="bg-[#161B22] rounded-lg">
                <div className="p-4">
                  <NewsList 
                    compact 
                    limit={5} 
                    title="Latest Headlines"
                    showLastUpdate
                  />
                </div>
              </section>

              {/* Trending Videos */}
              <section className="bg-[#161B22] rounded-lg">
                <h2 className="text-lg font-bold p-4 border-b border-gray-800 uppercase">Trending Videos</h2>
                <div className="p-4">
                  <TrendingVideos />
                </div>
              </section>

              {/* Sidebar Banner Ad */}
              <SidebarAd />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}; 