import { ReactNode } from 'react';
import { Header } from './Header';
import { Link } from 'react-router-dom';

const CATEGORIES = [
  { name: 'BITCOIN', slug: 'bitcoin' },
  { name: 'ETHEREUM', slug: 'ethereum' },
  { name: 'DEFI', slug: 'defi' },
  { name: 'NFT & GAMING', slug: 'nft-gaming' },
  { name: 'MINING', slug: 'mining' },
  { name: 'REGULATION', slug: 'regulation' },
  { name: 'WEB3', slug: 'web3' },
  { name: 'METAVERSE', slug: 'metaverse' }
];

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="min-h-screen bg-[#0D1117]">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {children}
      </main>
      <footer className="w-full bg-[#161B22] border-t border-gray-800 mt-16">
        <div className="max-w-6xl mx-auto px-4 py-12">
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-8">
            {CATEGORIES.map((category) => (
              <div key={category.slug}>
                <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider">{category.name}</h3>
                <ul className="mt-4 space-y-2">
                  <li><Link to={`/c/${category.slug}`} className="text-gray-300 hover:text-white text-sm">Latest News</Link></li>
                  <li><Link to={`/c/${category.slug}`} className="text-gray-300 hover:text-white text-sm">Analysis</Link></li>
                  <li><Link to={`/c/${category.slug}`} className="text-gray-300 hover:text-white text-sm">Price Action</Link></li>
                </ul>
              </div>
            ))}
          </div>
          <div className="mt-8 border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-gray-400 text-sm">© {new Date().getFullYear()} Aderlas. All rights reserved.</p>
            <div className="flex space-x-6">
              <Link to="/about" className="text-gray-400 hover:text-white text-sm">About Us</Link>
              <Link to="/privacy" className="text-gray-400 hover:text-white text-sm">Privacy Policy</Link>
              <Link to="/terms" className="text-gray-400 hover:text-white text-sm">Terms of Use</Link>
              <Link to="/adsreference" className="text-gray-400 hover:text-white text-sm">Advertising</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 